import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Layout } from '../../components/Layout'
import { Heading2, Heading4, Heading5 } from '../../components/Heading'

import { CardTitle } from '../../components/CardTitle'
import { MailboxIcon } from '../../components/Icon'
// import { Card } from '../../components/Card'
// import { Scroller } from '../../components/Scroller'
import { Emails } from '../../components/Emails'

import './index.scss'

interface Job {
  title: string
  description: string
}

interface ContactData {
  title: string
  head: SectionCard
  mailbox: SectionCard
  position: SectionCard
  slogan: string
}

export const ContactPage: React.FC<
  PageData<{ contactUs: APIResponse<ContactData>; jobs: APIListResponse<Job> }>
> = ({ pageContext }) => {
  const {
    serverData: { contactUs },
  } = pageContext

  return (
    <Layout className="contact-page" showContact={false}>
      <Helmet>
        <title>{contactUs.data.attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{contactUs.data.attributes.head.title}</Heading2>
      </Container>
      <Container className="mailbox">
        <div className="title">
          <CardTitle>
            <MailboxIcon />
            <Heading4>{contactUs.data.attributes.mailbox.title}</Heading4>
          </CardTitle>
        </div>
        <div className="box">
          <div className="description">
            <Heading5 as="p">
              {contactUs.data.attributes.mailbox.description}
            </Heading5>
            <Emails color={'black'} />
          </div>
        </div>
      </Container>
      {/* <Container className="jobs">
        <div className="title">
          <CardTitle>
            <BagIcon />
            <Heading4>{contactUs.data.attributes.position.title}</Heading4>
          </CardTitle>
        </div>
        <Scroller nums={jobs.data.length} hideNums={true}>
          {jobs.data.map((job, index) => {
            return (
              <Col md={3} lg={3} key={job.id + index.toString()}>
                <Card
                  key={job.id}
                  title={job.attributes.title}
                  content={job.attributes.description}
                  icon={<JobIcon />}></Card>
              </Col>
            )
          })}
          {new Array(3).fill(3).map((key, index) => {
            return (
              <Col
                md={3}
                lg={3}
                key={key + index.toString()}
                className="hidden"
              />
            )
          })}
        </Scroller>
      </Container> */}
      <Container className="slogan">
        <Heading4>{contactUs.data.attributes.slogan}</Heading4>
      </Container>
    </Layout>
  )
}
